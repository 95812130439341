// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-mdx-parent-file-name-js": () => import("./../../../src/pages/blog/{Mdx.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-blog-mdx-parent-file-name-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-js": () => import("./../../../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-pages-temp-js": () => import("./../../../src/pages/temp.js" /* webpackChunkName: "component---src-pages-temp-js" */),
  "component---src-pages-wall-js": () => import("./../../../src/pages/wall.js" /* webpackChunkName: "component---src-pages-wall-js" */)
}

